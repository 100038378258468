<template>
  <main>
    <PageTitle
      :title="`獎項管理 - ${displayData.baseInfo.name}`"
      icon="chevron_left"
      btn="基本設定"
      btn2="複製獎勵發放頁連結"
      :btn2Plain="true"
      @btnClick="modal.editBasicInfo = true"
      @btn2Click="copyLink(configData.link)"
      @iconClick="$router.push({ name: 'RewardDistributionSetting' })"
    />
    <section v-loading="loading" class="lottery-wrap">
      <BaseElForm label-position="left" label-width="140px">
        <BaseElFormItem label="活動期間">
          <p>{{ displayData.baseInfo.startAt }} ~ {{ displayData.baseInfo.endAt }}</p>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <section v-loading="loading">
      <RewardDistributionRewardTable
        :tableData="displayData.rewardList"
        :configData="configData"
        @refresh="refresh"
      />
    </section>

    <EditRewardDistributionInfoModal
      v-if="modal.editBasicInfo"
      :configData="configData"
      @close="modal.editBasicInfo = false"
      @refresh="refresh"
    />
  </main>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { formatDate } from '@/utils/date'
import { get, find, toNumber, isEmpty } from 'lodash'
import copy from 'clipboard-copy'
import Tipinfo from '@/components/TipInfo.vue'
import RewardDistributionRewardTable from './components/RewardDistributionRewardTable.vue'
import { FindRewardDistribution } from '@/api/rewardDistribution'
import { useShop } from '@/use/shop'
import { useRoute, useRouter } from 'vue-router/composables'
import EditRewardDistributionInfoModal from './components/EditRewardDistributionInfoModal.vue'
import { awardTypeConfig } from '@/config/rewardDistribution'

export default defineComponent({
  name: 'RewardDistributionRewardSetting',
  components: {
    PageTitle,
    // Tipinfo,
    RewardDistributionRewardTable,
    EditRewardDistributionInfoModal,
  },
  setup () {
    const { shopId } = useShop()
    const route = useRoute()
    const router = useRouter()
    const configData = ref({})
    const modal = reactive({
      editBasicInfo: false,
      editReward: false,
    })

    const loading = ref(false)

    const displayData = computed(() => {
      const rewardData = get(configData.value, 'award')
      const rewardAvailable = get(rewardData, 'isAwardAvailable')
      const firstError = get(rewardData, 'errors[0]')

      let status
      if (firstError || !rewardAvailable) {
        status = get(awardTypeConfig, firstError?.type)
      } else {
        status = get(awardTypeConfig, 'normal')
      }

      const unlimitedStock = get(configData.value, 'unlimitedStock')
      const availableStock = toNumber(get(configData.value, 'availableStock'))
      const usedStock = toNumber(get(configData.value, 'usedStock'))

      if (isEmpty(configData)) {
        return {
          baseInfo: {
            name: '',
            startAt: '',
            endAt: '',
          },
          rewardList: [],
        }
      }

      return {
        baseInfo: {
          name: get(configData.value, 'name'),
          startAt: formatDate(get(configData.value, 'startAt')),
          endAt: formatDate(get(configData.value, 'endAt')),
        },
        firstError,
        rewardList: [
          {
            name: get(configData.value, 'awardName'),
            data: get(configData.value, 'award'),
            status,
            availableStock: unlimitedStock ? '無上限' : get(configData.value, 'availableStock'),
            usedStock,
            remainStock: unlimitedStock ? '無上限' : availableStock - usedStock,
          },
        ],
      }
    })

    const copyLink = (url) => {
      copy(url)
      window.$message.success('已成功複製連結！')
    }

    const refresh = async () => {
      loading.value = true
      const [res, err] = await FindRewardDistribution({
        shopId: shopId.value,
        id: route.params.activityId,
      })
      loading.value = false
      configData.value = res
    }

    onBeforeMount(async () => {
      refresh()
    })

    return { displayData, copyLink, modal, configData, refresh, loading }
  },
})

</script>

<style scoped lang="postcss">
.lottery-wrap {
  @apply ml-[32px];
}
::v-deep .lottery-wrap .el-form-item {
  @apply mb-[12px];
}
::v-deep .lottery-wrap .el-form-item__content {
  @apply leading-normal text-gray-60 text-normal;
}
::v-deep .el-form-item__label {
  line-height: unset;
  @apply text-gray-100 text-normal;
}

.lottery-link {
  @apply flex items-center justify-between bg-white rounded-full py-[8px] px-[20px];
  @apply w-[472px];

  .copy-btn {
    @apply bg-secondary-100 text-white rounded-full cursor-pointer;
    @apply px-[30px] py-[1px] ml-[12px] w-[96px] whitespace-nowrap;
  }
}

.lottery-info {
  @apply text-normal font-normal leading-[24px] tracking-[1px];
}

.url-text {
  @apply text-gray-80 mr-[30px];
  @apply w-[250px] whitespace-nowrap overflow-hidden overflow-ellipsis;
}
.btn {
  @apply min-w-[100px] text-normal;
}
.warning {
  @apply text-warning text-sm leading-normal;
}
.danger {
  @apply text-danger text-sm leading-normal;
}
.gray {
  @apply text-gray-80 text-sm leading-normal;
}
.primary {
  @apply text-primary-100 text-sm leading-normal;
}
</style>
