<template>
  <div class="reward-manage-table">
    <BaseTable :data="displayData">
      <BaseElTableColumn label="獎項名稱" prop="name" align="center" />
      <BaseElTableColumn label="獎項狀態" prop="type" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'status.tagType')">
            {{ get(scope.row, 'status.label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="獎項數量" prop="availableStock" align="center" />
      <BaseElTableColumn label="送出數量" prop="usedStock" align="center" />
      <BaseElTableColumn label="剩餘數量" prop="remainStock" align="center" />
      <BaseElTableColumn
        prop="name"
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            :testName="scope.row.name"
            hideDelete
            @edit="onEditDialog(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <EditRewardDistributionRewardModal
      v-if="modal.edit"
      :selectRow="selectRow"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, toRef, reactive } from 'vue'
import { awardTypeConfig } from '@/config/rewardDistribution'
import { map, get, find } from 'lodash'
import { toFixed } from '@/utils/number'
import EditRewardDistributionRewardModal from './EditRewardDistributionRewardModal.vue'

export default defineComponent({
  name: 'RewardDistributionRewardTable',
  components: {
    EditRewardDistributionRewardModal,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const selectRow = ref(null)
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      const award = props.tableData[0]

      return [{
        name: get(award, 'name'),
        data: get(award, 'data'),
        status: get(award, 'status'),
        availableStock: get(award, 'availableStock'),
        usedStock: get(award, 'usedStock'),
        remainStock: get(award, 'remainStock'),
      }]
    })
    const onEditDialog = (row) => {
      selectRow.value = row
      modal.edit = true
    }

    const awardTagType = (val) => {
      return get(awardTypeConfig[val], 'tagType')
    }
    const awardTrans = (val) => {
      return get(awardTypeConfig[val], 'label')
    }

    const rounded = (value) => {
      return toFixed(value, 2)
    }
    return {
      displayData,
      rounded,
      onEditDialog,
      awardTagType,
      awardTrans,
      modal,
      selectRow,
      get,
    }
  },

})
</script>

<style lang="scss" scoped>
</style>
