<template>
  <BaseDialog
    v-bind="$attrs"
    title="獎勵發放基本設定"
    width="620px"
    v-on="$listeners"
    @confirm="onConfirm"
    @cancel="onCancel"
  >
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem label="頁面圖片">
        <!-- <img :src="imgUrl(lottery.Image)" alt=""> -->
        <UploadButton
          :img="formData.img"
          :isAvatar="true"
          @change="loadImg"
        />
      </BaseElFormItem>
      <BaseElFormItem label="獎勵發放名稱">
        <span class="lottery-info">{{ formData.name }}</span>
      </BaseElFormItem>
      <BaseElFormItem label="獎勵發放條件設定">
        <p
          v-for="(req, index) in lotteryReqList()"
          :key="index"
          class="lottery-info"
          style="margin-bottom: 10px"
        >
          {{ req }}
        </p>
      </BaseElFormItem>
      <BaseElFormItem label="獎勵發放活動期間" prop="lotteryDate" :error="dateError? '活動結束時間不可晚於使用券到期時間。': ''">
        <el-date-picker
          v-model="formData.lotteryDate"
          data-testid="formData_date"
          editable
          format="yyyy-MM-dd HH:mm"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          @change="(val) => (formData.lotteryDate = val)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="活動說明">
        <quillEditor v-model="formData.description" :options="editorOption" />
      </BaseElFormItem>
      <BaseElFormItem label="注意事項">
        <quillEditor v-model="formData.notice" :options="editorOption" />
      </BaseElFormItem>
    </BaseElForm>

    <ImageCropper
      v-if="modal.cropper"
      :image="imagePlaceHolder"
      @close="modal.cropper = false"
      @uploaded="getImage"
    />
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

// Editor
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { UpdateMemberGame } from '@/api/lottery/memberGame'
import { get, map, forEach, find } from 'lodash'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useShop } from '@/use/shop'
import { useRoute } from 'vue-router/composables'
import notifyMessage from '@/config/notifyMessage'
import { noEmptyRules, rangeRules } from '@/validation'
import { UpdateRewardDistribution } from '@/api/rewardDistribution'
import ImageCropper from '@/components/ImageCropper.vue'
import { useFetch } from '@/use/fetch'
import { lotteryReqConfig } from '@/config/lottery'
import { GetMemberLevelConfig } from '@/api/memberLevel'
import UploadButton from '@/components/Button/UploadButton.vue'
import dayjs from '@/lib/dayjs'
import { FindCoupon } from '@/api/lottery/coupon'

export default defineComponent({
  name: 'EditRewardDistributionInfoModal',
  components: { BaseDialog, quillEditor, ImageCropper, UploadButton },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { simpleFetch } = useFetch()

    const { shopId } = useShop()
    const route = useRoute()

    initFormData({
      lotteryDate: [],
      description: '',
      notice: '',
      img: null,
      coupon: null,
    })
    const modal = reactive({
      cropper: false,
    })

    const editorOption = {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    }
    const dateError = ref(false)
    const imagePlaceHolder = ref(null)
    const lotteryReq = reactive(lotteryReqConfig)
    const memberLevelConfig = ref([])

    const formRules = reactive({
      lotteryDate: [
        {
          required: true,
          message: '請輸入資料',
          trigger: 'blur',
        },
      ],
      // img: [noEmptyRules()],
      name: [noEmptyRules()],
      newProbability: [noEmptyRules(), rangeRules(0, 100)],
      availableStock: [noEmptyRules(), rangeRules(-1)],
      type: [noEmptyRules()],
      amount: [noEmptyRules(), rangeRules()],
      couponId: [noEmptyRules()],
    })

    const awardData = computed(() => {
      return get(props.configData, 'award.StandardAward')
    })

    const findCoupon = async (couponId) => {
      const [res, err] = await FindCoupon({
        shopId: shopId.value,
        id: couponId,
      })

      if (err) {
        if (err.errorCode === 'FINDONE_NOT_FOUND') {
          formData.status = 'notExist'
          return window.$message.error(err.msg)
        }
      }
      formData.coupon = res
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true

      if (get(awardData.value, 'type') === 'coupon') {
        await findCoupon(get(awardData.value, 'CouponId'))
        if (get(formData.coupon, 'expType') === 'expAt') {
          if (dayjs(formData.coupon.expAt).isBefore(dayjs(formData.lotteryDate[1]))) {
            loading.value = false
            dateError.value = true
            window.$message.warning('使用券截止日期不可早於活動結束日')
            return
          }
        }
      }

      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      const [, err] = await UpdateRewardDistribution({
        shopId: shopId.value,
        id: props.configData.id,
        startAt: formData.lotteryDate[0],
        endAt: formData.lotteryDate[1],
        description: formData.description,
        notice: formData.notice,
        rewardDistributionImageId: get(formData.img, 'id'),
      })
      loading.value = false
      if (err) return window.$message.error(err)
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }
    const onCancel = () => {
      emit('close')
    }

    const loadImg = (img) => {
      imagePlaceHolder.value = img
      modal.cropper = true
    }

    const getImage = (data) => {
      formData.img = data
      modal.cropper = false
    }

    const lotteryReqList = () => {
      const res = []
      forEach(get(props.configData, 'requirements'), (req) => {
        res.push(lotteryReq[req.type].name)

        if (req.type === 'memberCard') {
          const levelList = map(
            req.levels,
            (level) => get(find(memberLevelConfig.value, { level }), 'name'),
          )
          res.push(levelList.join('、'))
        }
        if (req.type === 'totalUsed' || req.type === 'dayUsed') {
          res.push(req.times)
        }
        if (req.type === 'costPoint') {
          res.push(`每次扣除 ${req.costAmount} 點`)
        }

        // TODO 會員標籤關聯? 難道要打API拿所有標籤比對?
        if (req.type === 'memberTag') {
          const tags = map(req.mTags, 'name')
          res.push(tags.join('、'))
        }
      })
      return res
    }

    const syncFormData = () => {
      formData.name = get(props.configData, 'name')
      formData.lotteryDate = [
        get(props.configData, 'startAt'),
        get(props.configData, 'endAt'),
      ]
      formData.description = get(props.configData, 'description')
      formData.notice = get(props.configData, 'notice')
      formData.img = get(props.configData, 'Image')
    }

    onBeforeMount(async () => {
      simpleFetch(GetMemberLevelConfig, {
        shopId: shopId.value,
      }, (res) => {
        console.log('res', res)
        memberLevelConfig.value = res
      })
    })

    onMounted(() => {
      syncFormData()
    })

    return {
      onConfirm,
      formData,
      formRef,
      initFormData,
      loading,
      onCancel,
      formRules,
      imagePlaceHolder,
      modal,
      loadImg,
      getImage,
      lotteryReqList,
      editorOption,
      dateError,
      memberLevelConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
</style>
