import { render, staticRenderFns } from "./EditRewardDistributionInfoModal.vue?vue&type=template&id=34a2fcd8&scoped=true"
import script from "./EditRewardDistributionInfoModal.vue?vue&type=script&lang=js"
export * from "./EditRewardDistributionInfoModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a2fcd8",
  null
  
)

export default component.exports